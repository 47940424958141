import { memo } from 'react'
import { useQuery } from 'react-query'
import { decode } from 'html-entities'

import { URLS } from 'shared/constants'
import { getSearchResults } from 'api/api'
import { getRedirectUrl } from './Search.helpers'

import './search.css'
import CaretRightIcon from 'assets/images/caret-right.svg'
import Modal from 'components/UI/Modal/Modal'
import Spinner from 'components/UI/Spinner/Spinner'

const SearchResults = ({ phrase, redirectToRef }) => {
  phrase = phrase.trim().toLowerCase()

  // eslint-disable-next-line
  const { isLoading, isError, data, error } = useQuery(
    [URLS.SEARCH.replace('{phrase}', phrase)],
    () => getSearchResults(phrase),
    {},
  )

  if (phrase === '') return

  redirectToRef.current = getRedirectUrl(data?.data?.results, phrase)

  const Results = () => {
    const results = data?.data?.results ?? []

    if (isLoading) return <Spinner centered />

    if (isLoading === false && results.length === 0) return

    const ResultItem = ({ href, text }) => (
      <li className="search_results__item">
        <a href={href}>
          <img src={CaretRightIcon} alt="caret-right_icon" />
          {text}
        </a>
      </li>
    )

    return (
      <ul>
        {results.map((i, index) => (
          <ResultItem key={index} text={decode(i.text)} href={i.url} />
        ))}
      </ul>
    )
  }

  return (
    <Modal>
      <div className="search_results custom_scrollbar">
        <Results />
      </div>
    </Modal>
  )
}

export default memo(SearchResults)
