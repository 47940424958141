import {
  useState,
  useEffect,
  memo,
  useCallback,
  useRef,
  useContext,
} from 'react'

import { shareContext } from 'api/api'

import {
  getSourcesObj,
  getDatesObj,
  getDestinationsObj,
  getOthersObj,
  findIdArr,
  findIdArrForDestination,
  getDataForTM,
  GETTERS,
} from './SearchTrip.helpers'

import { TYPES, CAPTIONS } from './SearchTrip.constants'

import ReferenceDataContext from 'context/ReferenceDataContext'

import './searchTrip.css'
import Box from 'components/UI/Box/Box'
import ButtonAsA from 'components/UI/buttons/ButtonAsA'
import DateOptions from 'components/SearchTrip/OptionsComponents/DateOptions/DateOptions'
import DestinationOptions from 'components/SearchTrip/OptionsComponents/DestinationOptions/DestinationOptions'
import OtherOptions from 'components/SearchTrip/OptionsComponents/OtherOptions/OtherOptions'
import Portal from 'components/portals/Portal'
import SelectComponent from 'components/SearchTrip/SelectComponent/SelectComponent'
import SelectDestinations from 'components/SearchTrip/SelectDestinations/SelectDestinations'
import SourceOptions from 'components/SearchTrip/OptionsComponents/SourceOptions/SourceOptions'
import DropdownButton from 'components/SearchTrip//Buttons/DropdownButton'
import TrashButton from 'components/SearchTrip/Buttons/TrashButton'
import ShareButton from 'components/SearchTrip/Buttons/ShareButton'
import ShareButtons from 'components/ShareButtons/ShareButtons'

const defaultDropdownsVisibility = {
  [TYPES.SOURCE]: false,
  [TYPES.DATE]: false,
  [TYPES.DESTINATION]: false,
  [TYPES.OTHER]: false,
}

const SearchTrip = ({
  TM,
  updateFiltersAndFetch,
  areSearchTripFiltersApplied,
  setAreSearchTripFiltersApplied,
  updateTitlesEnabled,
  productionMode = true,
  visible = false,
}) => {
  const { referenceData: rd } = useContext(ReferenceDataContext)
  const [sources, setSources] = useState(getSourcesObj(rd, true, TM))
  const [dates, setDates] = useState(getDatesObj(true, TM))
  const [destinations, setDestinations] = useState(
    getDestinationsObj(rd, true, TM),
  )
  const [others, setOthers] = useState(getOthersObj(rd, true, TM))
  const [dropdownsVisibility, setDropdownsVisibility] = useState({
    ...defaultDropdownsVisibility,
  })
  const [showShare, setShowShare] = useState(false)
  const [isSendingShareRequest, setIsSendingShareRequest] = useState(false)
  const urlRef = useRef()
  const changePriceTimeRef = useRef()

  // is form collapsed (CSS makes it only have effect for mobile)
  const [collapsed, setCollapsed] = useState(false)

  const collapseForm = useCallback((collapse) => {
    const searchTrip = document.getElementById("search_trip");
    if (!searchTrip) return

    if (collapse) {
      searchTrip.classList.add("stc_minimized")
    } else {
      searchTrip.classList.remove("stc_minimized")
    }

    setCollapsed(collapse)
  }, []);

  useEffect(() => {
    if (!TM.isEmpty()) collapseForm(false)

    if (!areSearchTripFiltersApplied) {
      setSources(getSourcesObj(rd, true, TM))
      setDates(getDatesObj(true, TM))
      setDestinations(getDestinationsObj(rd, true, TM))
      setOthers(getOthersObj(rd, true, TM))
      setAreSearchTripFiltersApplied(true)
    }
  }, [TM, rd, areSearchTripFiltersApplied, setAreSearchTripFiltersApplied, collapseForm])

  const switchDropdowns = useCallback((activeDropdown) => {
    collapseForm(false)

    const dv = { ...defaultDropdownsVisibility }
    if (activeDropdown) dv[activeDropdown] = true
    setDropdownsVisibility(dv)
  }, [collapseForm])

  const resetOptions = (typesArr) => {
    const updatedFilters = {}
    if (typesArr.includes(TYPES.SOURCE)) {
      const srcs = getSourcesObj(rd)
      updatedFilters.sources = srcs
      setSources(srcs)
    }
    if (typesArr.includes(TYPES.DATE)) {
      const dats = getDatesObj()
      updatedFilters.dates = dats
      setDates(dats)
    }
    if (typesArr.includes(TYPES.DESTINATION)) {
      const dess = getDestinationsObj(rd)
      updatedFilters.destinations = dess
      setDestinations(dess)
    }
    if (typesArr.includes(TYPES.OTHER)) {
      const oths = getOthersObj(rd)
      updatedFilters.others = oths
      setOthers(oths)
    }
    applyNewFilters(updatedFilters)
  }

  const restoreFilters = () => {
    TM.deactivateExternalFilters()

    updateTitlesEnabled.current = true
    setAreSearchTripFiltersApplied(false)
    updateFiltersAndFetch({}, true)
  }

  const resetAllOptions = () => {
    if (TM.filtersExternal) {
      restoreFilters()
      return
    }

    resetOptions(Object.values(TYPES))
  }

  const onClickOptionSource = (city, val) => {
    const idArr = findIdArr(sources, city)
    if (!idArr) return

    const [g, i] = idArr
    const newSources = [...sources]
    newSources[g].items[i].selected = val
    setSources(newSources)
    applyNewFilters({
      sources: newSources,
    })
  }

  const onClickOptionDate = (date, val) => {
    const idArr = findIdArr(dates, date)
    if (!idArr) return

    const [g, i] = idArr
    const newDates = [...dates]
    newDates[g].items[i].value = val || ''
    setDates(newDates)
    applyNewFilters({
      dates: newDates,
    })
  }

  const onClickOptionOther = (option, val) => {
    const idArr = findIdArr(others, option)
    if (!idArr) return

    const [g, i] = idArr
    const newOthers = [...others]
    if (newOthers[g].items[i].isTextField) {
      newOthers[g].items[i].value = val || ''
    } else {
      newOthers[g].items[i].selected = val
    }
    setOthers(newOthers)

    if ((option === 'Od:' || option === 'Do:') && val !== '') {
      changePriceTimeRef.current = new Date()
      setTimeout(() => {
        if (new Date() - changePriceTimeRef.current > 450) {
          applyNewFilters({
            others: newOthers,
          })
        }
      }, 500)
      return
    }

    applyNewFilters({
      others: newOthers,
    })
  }

  const onClickOptionDestination = (dest, val) => {
    const idArr = findIdArrForDestination(destinations, dest)
    if (!idArr) return

    const [g, c, regionIndex] = idArr
    const newDestinations = [...destinations]
    if (regionIndex !== undefined) {
      // region was clicked
      newDestinations[g].items[c].items[regionIndex].selected = val
      if (val && !newDestinations[g].items[c].selected) {
        // if region is selected than parent country should be selected too
        newDestinations[g].items[c].selected = true
      }
      if (!val) {
        // if clicked region is unselected and this was the last region selected then country should be unselected too
        const selectedRegionsCount = newDestinations[g].items[c].items.filter(
          (r) => r.selected,
        ).length
        if (selectedRegionsCount === 0) {
          newDestinations[g].items[c].selected = false
        }
      }
    } else {
      // country was clicked
      newDestinations[g].items[c].selected = val
      if (!val) {
        // if country is unselected than regions should be unselected too
        const updatedRegions = []
        for (let region of newDestinations[g].items[c].items) {
          updatedRegions.push({ ...region, selected: false })
        }
        newDestinations[g].items[c].items = updatedRegions
      }
    }
    setDestinations(newDestinations)
    applyNewFilters({
      destinations: newDestinations,
    })
  }

  const applyNewFilters = (changedState) => {
    const state = (changedState.sources
      ? [...changedState.sources]
      : [...sources]
    )
      .concat(changedState.dates ? [...changedState.dates] : [...dates])
      .concat(changedState.others ? [...changedState.others] : [...others])
      .concat(
        changedState.destinations
          ? [...changedState.destinations]
          : [...destinations],
      )

    const [checkAndFilter, checkNotFilter] = getDataForTM(rd, state)
    updateTitlesEnabled.current = true
    updateFiltersAndFetch({
      checkAND: { ...checkAndFilter },
      checkNOT: [...checkNotFilter],
    })
  }

  const areFiltersEmpty = () => {
    const selectedFilters = GETTERS[TYPES.SOURCE](sources)
      .concat(GETTERS[TYPES.DESTINATION](destinations))
      .concat(GETTERS[TYPES.DATE](dates))
      .concat(GETTERS[TYPES.OTHER](others))

    return selectedFilters.length === 0
  }

  const share = useCallback(async () => {
    setIsSendingShareRequest(true)
    shareContext({
      ...TM.getFilters(),
    })
      .then((res) => {
        const url = res.data?.url
        if (url) {
          urlRef.current = url
          setShowShare(true)
        }
      })
      .catch((_) => { })
      .finally(() => {
        setIsSendingShareRequest(false)
      })
  }, [TM])

  const ShareAndTrash = () => (
    <>
      {showShare && (
        <ShareButtons
          iconId="share_icon"
          url={urlRef.current}
          title={''}
          onClose={() => setShowShare(false)}
          offsetRight
        />
      )}
      <ShareButton onClick={share} pulsing={isSendingShareRequest} />
      <TrashButton onClick={resetAllOptions} />
    </>
  )

  const showRestoreFiltersLink = TM.filtersExternal && TM.filtersRestoreExist()

  return productionMode ? (
    <>
      <Portal
        elementId="portal-search-trips-trash"
        component={
          <>
            <DropdownButton
              collapsed={collapsed}
              onClick={() => collapseForm(!collapsed)}
              forceVisibleOnMobile={areFiltersEmpty()}
            />
            {!areFiltersEmpty() && <ShareAndTrash />}
          </>
        }
      />
      <Portal
        elementId="portal-search-trips-source"
        component={
          <SelectComponent
            options={sources}
            type={TYPES.SOURCE}
            onClickOption={onClickOptionSource}
            isDropdownOpen={dropdownsVisibility[TYPES.SOURCE]}
            switchDropdowns={switchDropdowns}
            resetOptions={resetOptions}
            size="sm"
            optionsComponent={
              <SourceOptions
                options={sources}
                onClickOption={onClickOptionSource}
              />
            }
          />
        }
      />
      <Portal
        elementId="portal-search-trips-date"
        component={
          <SelectComponent
            options={dates}
            type={TYPES.DATE}
            onClickOption={onClickOptionDate}
            isDropdownOpen={dropdownsVisibility[TYPES.DATE]}
            switchDropdowns={switchDropdowns}
            resetOptions={resetOptions}
            size="lg"
            optionsComponent={
              <DateOptions options={dates} onClickOption={onClickOptionDate} />
            }
          />
        }
      />
      <Portal
        elementId="portal-search-trips-destination"
        component={
          <SelectDestinations
            options={destinations}
            type={TYPES.DESTINATION}
            onClickOption={onClickOptionDestination}
            isDropdownOpen={dropdownsVisibility[TYPES.DESTINATION]}
            switchDropdowns={switchDropdowns}
            resetOptions={resetOptions}
            limitDropdownHeight
            optionsComponent={
              <DestinationOptions
                options={destinations}
                onClickOption={onClickOptionDestination}
              />
            }
          />
        }
      />
      <Portal
        elementId="portal-search-trips-other"
        component={
          <SelectComponent
            options={others}
            type={TYPES.OTHER}
            onClickOption={onClickOptionOther}
            isDropdownOpen={dropdownsVisibility[TYPES.OTHER]}
            switchDropdowns={switchDropdowns}
            resetOptions={resetOptions}
            optionsComponent={
              <OtherOptions
                options={others}
                onClickOption={onClickOptionOther}
              />
            }
          />
        }
      />
      {showRestoreFiltersLink && (
        <Portal
          elementId="portal-search-trips-restore-filters"
          component={<RestoreFilters TM={TM} onClick={restoreFilters} />}
        />
      )}
    </>
  ) : (
    <Box
      id="search_trip"
      style={{ display: visible ? 'block' : 'none' }}
      classes={"search_trip__container"}
    >
      <>
        <DropdownButton
          collapsed={collapsed}
          onClick={() => collapseForm(!collapsed)}
          forceVisibleOnMobile={areFiltersEmpty()}
        />
        {!areFiltersEmpty() && <ShareAndTrash />}
      </>
      <SearchTripContainer>
        <SelectComponentContainer
          component={
            <SelectComponent
              options={sources}
              type={TYPES.SOURCE}
              onClickOption={onClickOptionSource}
              isDropdownOpen={dropdownsVisibility[TYPES.SOURCE]}
              switchDropdowns={switchDropdowns}
              resetOptions={resetOptions}
              size="sm"
              optionsComponent={
                <SourceOptions
                  options={sources}
                  onClickOption={onClickOptionSource}
                />
              }
            />
          }
          type={TYPES.SOURCE}
        />
        <SelectComponentContainer
          component={
            <SelectComponent
              options={dates}
              type={TYPES.DATE}
              onClickOption={onClickOptionDate}
              isDropdownOpen={dropdownsVisibility[TYPES.DATE]}
              switchDropdowns={switchDropdowns}
              resetOptions={resetOptions}
              size="lg"
              optionsComponent={
                <DateOptions
                  options={dates}
                  onClickOption={onClickOptionDate}
                />
              }
            />
          }
          type={TYPES.DATE}
        />
        <SelectComponentContainer
          component={
            <SelectDestinations
              options={destinations}
              type={TYPES.DESTINATION}
              onClickOption={onClickOptionDestination}
              isDropdownOpen={dropdownsVisibility[TYPES.DESTINATION]}
              switchDropdowns={switchDropdowns}
              resetOptions={resetOptions}
              limitDropdownHeight
              optionsComponent={
                <DestinationOptions
                  options={destinations}
                  onClickOption={onClickOptionDestination}
                />
              }
            />
          }
          type={TYPES.DESTINATION}
        />
        <SelectComponentContainer
          component={
            <SelectComponent
              options={others}
              type={TYPES.OTHER}
              onClickOption={onClickOptionOther}
              isDropdownOpen={dropdownsVisibility[TYPES.OTHER]}
              switchDropdowns={switchDropdowns}
              resetOptions={resetOptions}
              optionsComponent={
                <OtherOptions
                  options={others}
                  onClickOption={onClickOptionOther}
                />
              }
            />
          }
          type={TYPES.OTHER}
        />
      </SearchTripContainer>
      {showRestoreFiltersLink && (
        <RestoreFilters TM={TM} onClick={restoreFilters} />
      )}
    </Box>
  )
}

export default memo(SearchTrip)

const RestoreFilters = ({ TM, onClick }) => {
  const filters = TM.getFiltersList(true, true).join(', ')
  const label = `Przywróć poprzednie kryteria: ${filters}`

  return (
    <div className="search_trip__restore_filters">
      <ButtonAsA label={label} onClick={onClick} alignLeft />
    </div>
  )
}

const SelectComponentContainer = ({ component, type }) => (
  <div className="select_component__container">
    <label className="select_component__label">{CAPTIONS[type].label}</label>
    <div className="options_selected__container">{component}</div>
  </div>
)

const SearchTripContainer = ({ children }) => (
  <>
    <h3 className="search_trip__title">Znajdź swoją wycieczkę (DEV)</h3>
    <div className="search_trip__options_container">{children}</div>
  </>
)
