import { useEffect, useRef } from 'react'

const useOutsideClick = (
  callback, // callback made after clicking outside the object
  // callback is not executed if list contains the id of clicked item,
  skipElementIdList = [],
) => {
  const ref = useRef()

  useEffect(() => {
    const handleClick = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !skipElementIdList.includes(event.target.id)
      ) {
        callback(event)
      }
    }

    document.addEventListener('click', handleClick, true)

    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  }, [ref, callback, skipElementIdList])

  return ref
}

export { useOutsideClick }
