import { useState } from 'react'

import { useLocalStorage } from 'hooks/useLocalStorage'
import { LOCAL_STORAGE_KEYS, URLS } from 'shared/constants'

import './cookieInformation.css'
import Button from 'components/UI/buttons/Button'

const CookieInformation = () => {
  const [showFullInfo, setShowFullInfo] = useState(false)
  const [cookiesAccepted, setCookiesAccepted] = useLocalStorage(
    LOCAL_STORAGE_KEYS.COOKIES_ACCEPTED,
    null,
  )

  if (cookiesAccepted) return

  const ShowMoreButton = () => (
    <button
      onClick={() => setShowFullInfo(true)}
      className="cookie_info__show_more"
    >
      Dalej &raquo;
    </button>
  )

  return (
    <div id="cookie_info">
      <div className="cookie_info__text">
        <p className={showFullInfo ? null : 'cit_collapsed'}>
          Lastminuter używa technologii, takich jak pliki cookies, do zbierania
          i przetwarzania danych osobowych w celu automatycznego
          personalizowania treści i reklam oraz analizowania ruchu na stronach
          Serwisu. Technologię tę wykorzystują również nasi Partnerzy.
          Szczegółowe informacje dotyczące plików Cookies oraz zasad
          przetwarzania danych osobowych znajdują się w{' '}
          <a
            href={URLS.ALERTS_TERMS_AND_CONDITIONS}
            target="_blank"
            rel="noreferrer"
          >
            Polityce Prywatności
          </a>
          . Zapoznaj się z tymi informacjami przed korzystaniem z Serwisu. Opuść
          stronę jeśli nie akceptujesz naszej polityki prywatności.
        </p>
        {!showFullInfo && <ShowMoreButton />}
      </div>
      <div className="cookie_info__buttons">
        <Button onClick={() => setCookiesAccepted(true)}>Akceptuję</Button>
      </div>
    </div>
  )
}

export default CookieInformation
