import { pluralizeGwiazdka } from 'shared/utils'

class TripsManager {
  constructor(referenceData, countryByRegion) {
    this.referenceData = referenceData
    this.countryByRegion = countryByRegion
    this.checkAND = {}
    this.checkNOT = []
    this.checkAndRestore = {}
    this.checkNotRestore = []
    this.filtersExternal = false
    this.sorting = 'price'
    this.page = 0
    this.DIMENSIONS = {
      startdate: 13,
      rating: 8,
      hotel: 16,
      enddate: 14,
      price: 2,
      country: 3,
      days: 15,
      daysrange: 6,
      source: 10,
      boarding: 7,
      region: 4,
    }
  }

  filtersRestoreExist = () =>
    !!Object.keys(this.checkAndRestore).length || !!this.checkNotRestore.length

  // returns checkAND converted to string
  getCheckAND(excludePrice = false) {
    if (!this.checkAND || Object.keys(this.checkAND).length === 0) return ''

    let res = ''
    let sep = ''
    for (let key in this.checkAND) {
      if (excludePrice && key === 'price') continue

      res += `${sep}${this.DIMENSIONS[key]}:`
      if (sep === '') sep = '|'

      switch (key) {
        case 'price':
          const minPrice = this.checkAND[key][0] ?? ''
          const maxPrice = this.checkAND[key][1] ?? ''
          res += `${minPrice}-${maxPrice}`
          break
        case 'startdate':
        case 'enddate':
          res += this.checkAND[key]
          break
        default:
          res += this.checkAND[key].join()
          break
      }
    }

    return res
  }

  // returns checkNOT converted to string
  getCheckNOT() {
    if (!this.checkNOT || this.checkNOT.length === 0) return ''

    return `${this.DIMENSIONS.region}:${this.checkNOT.join()}`
  }

  isEmpty() {
    return (this.getCheckNOT() + this.getCheckAND()) === ''
  }

  // returns get offers request payload
  getFilters() {
    return {
      filters_not: this.getCheckNOT(),
      filters_and: this.getCheckAND(),
      sorting: this.sorting,
      page: this.page,
      filters_ext: this.filtersExternal ? 1 : 0,
    }
  }

  // returns create alert request payload
  getAlertFilters() {
    return {
      filters_not: this.getCheckNOT(),
      filters_and: this.getCheckAND(true),
    }
  }

  // Returns array of all selected filters
  // ex. ['Warszawa', 'Katowice', 'Grecja', 'Dominikana', 'All inclusive']
  getFiltersList(restore = false, includePrice = false) {
    const checkAnd = restore ? this.checkAndRestore : this.checkAND

    const dateOutput = (dateStr, prefix) => {
      // Converts date string YYYY-MM-DD to [prefix] DD/MM
      const DD = dateStr.substr(8)
      const MM = dateStr.substr(5, 2)
      return `${prefix} ${DD}/${MM}`
    }

    const res = []

    if (checkAnd.country) {
      const selectedRegions = this.getSelectedRegions(restore)
      checkAnd.country.forEach((country) => {
        const regions = selectedRegions[country]
        if (regions) {
          res.push(...regions)
        } else {
          res.push(country)
        }
      })
    }

    if (checkAnd.source) res.push(...checkAnd.source)
    if (checkAnd.rating)
      res.push(...checkAnd.rating.map((i) => `${i} ${pluralizeGwiazdka(i)}`))
    if (checkAnd.hotel) res.push(...checkAnd.hotel)
    if (checkAnd.daysrange)
      res.push(...checkAnd.daysrange.map((i) => `${i} dni`))
    if (checkAnd.boarding) res.push(...checkAnd.boarding)
    if (checkAnd.startdate) res.push(dateOutput(checkAnd.startdate, 'od '))
    if (checkAnd.enddate) res.push(dateOutput(checkAnd.enddate, 'do '))
    if (includePrice && checkAnd.price) {
      const [min, max] = [...checkAnd.price]
      if (min) res.push(`od ${min} zł`)
      if (max) res.push(`do ${max} zł`)
    }

    return res
  }

  // Returns object with checkAND source and destination as strings, ex:
  // {
  //   sources: 'Warszawa,Katowice',
  //   destinations: 'Grecja,Dominikana',
  // }
  getUserPreferences() {
    const res = {}

    if (this.checkAND.country)
      res.destinations = this.checkAND.country.join(',')

    if (this.checkAND.source) res.sources = this.checkAND.source.join(',')

    return res
  }

  getFiltersForDestination(dest) {
    this.checkAND.country = [dest]
    return {
      filters_not: this.getCheckNOT(),
      filters_and: this.getCheckAND(),
      sorting: this.sorting,
      page: this.page,
    }
  }

  readFiltersFromHtml() {
    const controllerDiv = document.getElementById('controller')
    let filtersAnd = '',
      filtersNot = '',
      filtersAndRestore = '',
      filtersNotRestore = ''

    try {
      filtersAnd = controllerDiv.attributes['fil-and'].textContent
      filtersNot = controllerDiv.attributes['fil-not'].textContent
      filtersAndRestore =
        controllerDiv.attributes['fil-and-restore'].textContent
      filtersNotRestore =
        controllerDiv.attributes['fil-not-restore'].textContent
    } catch (err) {}

    this.setFilters(filtersAnd, filtersNot)
    this.setFilters(filtersAndRestore, filtersNotRestore, true)
    this.filtersExternal =
      this.filtersRestoreExist() || filtersAndRestore === 'x'
  }

  // Sets checkAND and checkNOT according to provided filterAnd string and filtersNot string;
  // If [restore] is true, then checkAndRestore and checkNotRestore are set
  setFilters(filtersAnd, filtersNot, restore = false) {
    let checkAnd = {}
    let checkNot = []

    const dimensionsReversed = {}
    for (let key in this.DIMENSIONS) {
      dimensionsReversed[this.DIMENSIONS[key]] = key
    }

    let colonIndex = filtersNot.indexOf(':')
    if (colonIndex !== -1) {
      checkNot = filtersNot.slice(colonIndex + 1).split(',')
    }

    while (filtersAnd.length > 0) {
      colonIndex = filtersAnd.indexOf(':')
      if (colonIndex === -1) break

      const key = filtersAnd.slice(0, colonIndex)
      let barIndex = filtersAnd.indexOf('|', colonIndex)

      let value = ''
      if (barIndex === -1) {
        value = filtersAnd.slice(colonIndex + 1)
        filtersAnd = ''
      } else {
        value = filtersAnd.slice(colonIndex + 1, barIndex)
        filtersAnd = filtersAnd.slice(barIndex + 1)
      }

      switch (key) {
        case '2':
          const price = value.split('-')
          checkAnd[dimensionsReversed[key]] = [
            price[0] || null,
            price[1] || null,
          ]
          break
        case '13':
        case '14':
          checkAnd[dimensionsReversed[key]] = value
          break
        default:
          checkAnd[dimensionsReversed[key]] = value.split(',')
      }
    }

    if (restore) {
      this.checkAndRestore = { ...checkAnd }
      this.checkNotRestore = [...checkNot]
    } else {
      this.checkAND = { ...checkAnd }
      this.checkNOT = [...checkNot]
    }
  }

  // Returns object with selected regions
  // {
  //   Chorwacja: ['Dalmacja', 'Dalmacja Południowa', 'Istria'],
  //   Maroko: ['Casablanca', 'Marakesz'],
  getSelectedRegions = (restore = false) => {
    const result = {}
    const checkNot = restore ? this.checkNotRestore : this.checkNOT
    const regionsNOTSet = new Set(checkNot || [])

    while (regionsNOTSet.size > 0) {
      const [region] = regionsNOTSet,
        country = this.countryByRegion[region]

      if (!country) {
        regionsNOTSet.delete(region)
        continue
      }

      const allCountryRegions = this.referenceData[country],
        selectedRegions = []

      allCountryRegions.forEach((reg) => {
        if (!regionsNOTSet.has(reg)) selectedRegions.push(reg)
        regionsNOTSet.delete(reg)
      })
      result[country] = selectedRegions
    }

    return result
  }

  activateExternalFilters = (filtersAnd, filtersNot) => {
    this.filtersExternal = true
    this.setFilters(filtersAnd, filtersNot)
  }

  deactivateExternalFilters = () => {
    this.filtersExternal = false
    this.checkAND = { ...this.checkAndRestore }
    this.checkNOT = [...this.checkNotRestore]
  }

  updateRestoreFilters = () => {
    if (this.filtersExternal) return

    this.checkAndRestore = { ...this.checkAND }
    this.checkNotRestore = [...this.checkNOT]
  }
}

export { TripsManager }
