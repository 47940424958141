import { useState, useCallback } from 'react'

import { TYPES, CAPTIONS } from 'components/SearchTrip/SearchTrip.constants'
import { GETTERS } from 'components/SearchTrip/SearchTrip.helpers'
import { getFilteredDestinations } from 'components/SearchTrip/SearchTrip.helpers'
import OptionsDropdown from 'components/SearchTrip/OptionsDropdown/OptionsDropdown'
import { OptionsList } from 'components/SearchTrip/OptionsSelected/OptionsSelected'
import TrashIcon from 'components/UI/icons/TrashIcon'
import DestinationOptionsFiltered from 'components/SearchTrip/OptionsComponents/DestinationOptions/DestinationOptionsFiltered'
import DropdownButton from 'components/UI/buttons/DropdownButton'
import DestinationInputField from './DestinationInputField'

const SelectDestinations = ({
  options,
  type,
  onClickOption,
  isDropdownOpen,
  switchDropdowns,
  resetOptions,
  limitDropdownHeight,
  optionsComponent,
  size,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [searchPhrase, setSearchPhrase] = useState('')

  const onKeystroke = (e) => {
    e.preventDefault()

    let value = e.target.value
    if (value === undefined) return

    setInputValue(value)
    setSearchPhrase(value.trim())
  }

  const toggleDropdown = () => switchDropdowns(isDropdownOpen ? '' : type)

  const closeDropdown = useCallback(
    (event) => {
      switchDropdowns('')
      event && event.stopPropagation()
    },
    [switchDropdowns],
  )

  const ResetButton = () => {
    const selectedOptions = GETTERS[TYPES[type]](options)

    let className = 'select_component__trash_icon select_dest__trash_icon icon_hover_effect'
    if (selectedOptions.length === 0) className += ' trash_icon_disabled'

    return (
      <TrashIcon
        onClick={() => resetOptions([type])}
        className={className}
        svgPathClass="trash_icon__color"
      />
    )
  }

  const handleClickMe = (e) => {
    const l = e.target.localName,
      c = e.target.className
    if (
      !(l === 'path') &&
      !(l === 'svg') &&
      !(l === 'img' && c === 'badge__icon') &&
      !(l === 'img' && c === 'dropdown_icon')
    ) {
      if (!isDropdownOpen) toggleDropdown()
    }
  }

  const isTypeAlert =
    [TYPES.ALERT_FROM, TYPES.ALERT_SEASON, TYPES.ALERT_TO].indexOf(type) !== -1

  let filteredRegions = [];
  let filteredCountries = [];
  if (isDropdownOpen && searchPhrase !== "") {
    const dest = getFilteredDestinations(options, searchPhrase);
    filteredCountries = dest.countries;
    filteredRegions = dest.regions;
  }

  const optionsSelected = GETTERS[TYPES[type]](options)

  return (
    <>
      <div className="options_selected__field" onClick={handleClickMe}>
        {optionsSelected.length === 0
          ? <span className="options_selected__placeholder">{CAPTIONS[type].placeholder}</span>
          : <OptionsList options={optionsSelected} onClickOption={onClickOption} />
        }
        <DropdownButton isOpen={isDropdownOpen} onClick={toggleDropdown} />
      </div>
      {isDropdownOpen && (
        <OptionsDropdown
          closeDropdown={closeDropdown}
          limitDropdownHeight={limitDropdownHeight}
          positionFixed={isTypeAlert}
          size={size}
          closeButtonExtraClassName="cb_dest_options_dropdown"
        >
          <ResetButton />
          <DestinationInputField
            value={inputValue}
            placeholder="Wpisz kierunek"
            onChange={onKeystroke}
            onClickX={() => {
              setInputValue("")
              setSearchPhrase("")
            }}
          />
          {searchPhrase === ""
            ? optionsComponent
            : <DestinationOptionsFiltered
              countries={filteredCountries}
              regions={filteredRegions}
              onClickOption={onClickOption}
            />
          }
        </OptionsDropdown>
      )}
    </>
  )
}

export default SelectDestinations
