import {
  useState,
  useEffect,
  useCallback,
  Fragment,
  useRef,
  useMemo,
  useContext,
} from 'react'

import { TripsManager } from 'api/tripsManager'
import { getOffers } from 'api/api'
import { dealSampleData as dealData } from 'shared/Deal/dealSampleData'
import {
  usePageStructure,
  useInfiniteScroll,
  useGetErrorFromURL,
  useHeaderIcons,
  useAlertPostButton,
  useTableHeaderIcons,
  useTripsTableTitles,
  useAlertPriceController,
  useNoScrollbarCallback,
} from 'App.hooks'
import { useMinimizedComponents } from 'hooks/useMinimizedComponents'

import { INFINITE_PRICE, LOCAL_STORAGE_KEYS as KEYS } from 'shared/constants'
import { getLocalStorageData, pushApiSupported } from 'shared/utils'
import {
  checkPromo,
  getMinPrice,
  getUrlSearchParams,
  getController,
  getRequestContext,
  parseAlertPriceController,
  showOtionallyExclamationMark,
} from 'App.helpers'

import AlertsContext from 'context/AlertsContext'
import IndexedDbContext from 'context/IndexedDbContext'
import ReferenceDataContext from 'context/ReferenceDataContext'

import Alerts from 'components/Alerts/Alerts'
import Apps from 'components/Apps/Apps'
import Box from 'components/UI/Box/Box'
import CookieInformation from 'components/Layout/CookieInformation/CookieInformation'
import Deal from 'components/Deal/Deal'
import DealPortal from 'components/portals/DealPortal'
import Deals from 'components/Deals/Deals'
import DontMiss from 'components/DontMiss/DontMiss'
import ErrorDialog from 'components/ErrorDialog/ErrorDialog'
import ErrorDialogPortal from 'components/portals/ErrorDialogPortal'
import ExtraPages from 'components/ExtraPages/ExtraPages'
import ExtraPagesPortal from 'components/portals/ExtraPagesPortal'
import HeaderInputFieldPortal from 'components/portals/HeaderInputFieldPortal'
import Layout from 'components/Layout/Layout'
import MobileAppsWidget from 'components/Widgets/MobileAppsWidget/MobileAppsWidget'
import Page from 'components/Page/Page'
import Promo from 'components/Promo/Promo'
import Search from 'components/Search/Search'
import SearchTrip from 'components/SearchTrip/SearchTrip'
import SearchTripPortal from 'components/portals/SearchTripPortal'
import SendUserPrefs from 'components/SendUserPrefs/SendUserPrefs'
import ShareButtons from 'components/ShareButtons/ShareButtons'
import SocialMediaWidget from 'components/Widgets/SocialMediaWidget/SocialMediaWidget'
// import SortingOptionsPortal from 'components/portals/SortingOptionsPortal'
import TripsTable from 'components/TripsTable/TripsTable'
import TableBorder from 'components/UI/TableBorder/TableBorder'
import TableHeader from 'components/TripsTable/TableHeader/TableHeader'
import TablePortal from 'components/portals/TripsTablePortal'

import {
  checkTokenNeedToBeRenewed,
  startFirebaseMessagingSWListener,
  updatePageTitleAndAlertsIcon,
} from './service/messaging/messaging'

const PRODUCTION_MODE = false
const SHOW_DEAL = false
const SHOW_PROMO = true
const HIDE_FILTERS = false

function App() {
  const { referenceData, countryByRegion } = useContext(ReferenceDataContext)
  const db = useContext(IndexedDbContext)
  const tmRef = useRef(new TripsManager(referenceData, countryByRegion))
  const [tripsArr, setTripsArr] = useState([])
  const [dealsArr, setDealsArr] = useState([])
  const [overrideStaticDeals, setOverrideStaticDeals] = useState(false)
  const [isLoadingTrips, setIsLoadingTrips] = useState(false)
  const initialTripsLoaded = useRef(false)
  const [extraPages, setExtraPages] = useState([])
  const [isLoadingAnotherPage, setIsLoadingAnotherPage] = useState(false)
  const pagesCount = useRef(1)
  const requestsFailedCounter = useRef(0)
  const [sendUserPrefs, setSendUserPrefs] = useState(false)
  const [
    pageStructure,
    tripsPortals,
    dealsPortals,
    hideStaticDeals,
  ] = usePageStructure()
  const [showSearchTrip, setShowSearchTrip] = useState(false)
  const [
    areSearchTripFiltersApplied,
    setAreSearchTripFiltersApplied,
  ] = useState(true)

  const [showSearchField, setShowSearchField] = useState(false)
  const [showApps, setShowApps] = useState(false)
  const [showAlerts, setShowAlerts] = useState(false)
  const [showShare, setShowShare] = useState(false)

  const newAlertPostRef = useRef(null)
  const minPriceRef = useRef(INFINITE_PRICE)
  const skipFetchingTripsRef = useRef(false)
  const skipFetchingDealsRef = useRef(false)
  const loadMoreRef = useRef(true)
  const reqContextRef = useRef()
  const updateTitlesEnabled = useRef(false) // will become true after first filters change
  const afterUserLogInCallbackRef = useRef()
  // used to refresh the alert panel after receiving notifications
  // eslint-disable-next-line
  const [refreshAlerts, setRefreshAlerts] = useState(false)

  const shareIcon = useMemo(() => document.getElementById('share_icon'), [])
  const headerInput = useMemo(
    () => document.getElementById('portal-header-input'),
    [],
  )

  const { closeApps, closeAlerts, closeShare, lockScroll } = useHeaderIcons(
    setShowApps,
    setShowSearchField,
    setShowAlerts,
    setShowShare,
    newAlertPostRef,
    shareIcon,
    headerInput,
  )

  useAlertPostButton(setShowAlerts, newAlertPostRef)
  
  useMinimizedComponents();

  const { hideSortingOptions, updateSortIconState } = useTableHeaderIcons()
  const updateTripsTableTitles = useTripsTableTitles()

  const fetchTrips = useCallback(
    async (filters = {}, alertTitles = {}) => {
      setIsLoadingTrips(true)
      await getOffers(filters)
        .then((res) => {
          if (alertTitles.h1 || alertTitles.h2) {
            updateTripsTableTitles(alertTitles.h1, alertTitles.h2)
          } else if (updateTitlesEnabled.current) {
            updateTripsTableTitles(res.data.h1, res.data.h2)
          }
          setTripsArr(res.data.trips)
          setDealsArr(res.data.deals)
          updateSortIconState(res.data.trips.length !== 0)
          minPriceRef.current = getMinPrice(res.data.trips)
          loadMoreRef.current = res.data.have_more
          reqContextRef.current = { context: res.data.context }
        })
        .catch((err) => { })

      setShowSearchTrip(true)
      setIsLoadingTrips(false)
      initialTripsLoaded.current = true
    },
    [updateTripsTableTitles, updateSortIconState],
  )

  const [errorCode, resetError] = useGetErrorFromURL()

  const { processAlertPriceController } = useAlertPriceController(
    tmRef,
    reqContextRef,
    afterUserLogInCallbackRef,
    fetchTrips,
    referenceData,
    countryByRegion,
  )

  useEffect(() => {
    showOtionallyExclamationMark()

    if (!pushApiSupported()) return

    startFirebaseMessagingSWListener(() => {
      setRefreshAlerts((x) => !x)
    })
    checkTokenNeedToBeRenewed()

    if (!getLocalStorageData(KEYS.USER_DATA)) {
      // czyszczenie wiadomosci na wypadek wyczyszczenia local storage
      db.clear().catch((e) => { })
    } else {
      updatePageTitleAndAlertsIcon()
    }

    if (window.location.hash === '#powiadomienia') setShowAlerts(true)
  }, [db])

  useEffect(() => {
    reqContextRef.current = getRequestContext()
    skipFetchingTripsRef.current = !!getController('no-trips')
    skipFetchingDealsRef.current = !!getController('no-deals')
    loadMoreRef.current =
      !skipFetchingTripsRef.current || !skipFetchingDealsRef.current
    tmRef.current.readFiltersFromHtml()

    const payload = {
      ...tmRef.current.getFilters(),
      ...getUrlSearchParams(),
      ...reqContextRef.current,
    }

    if (getController('alert-price')) {
      const id = parseAlertPriceController()
      if (id === null) {
        fetchTrips(payload)
        return
      }

      processAlertPriceController(id, payload)
    } else if (!skipFetchingTripsRef.current) {
      fetchTrips(payload)
    } else {
      setShowSearchTrip(true)
    }

    setSendUserPrefs(true)

    if (process.env.NODE_ENV === 'production' || PRODUCTION_MODE) checkPromo()
  }, [fetchTrips, referenceData, countryByRegion, processAlertPriceController])

  const loadAnotherPage = useCallback(() => {
    const skipTrips = skipFetchingTripsRef.current
    const skipDeals = skipFetchingDealsRef.current
    if (skipTrips && skipDeals) return
    if (!loadMoreRef.current) return

    setIsLoadingAnotherPage(true)
    tmRef.current.page++
    let payload = {
      ...tmRef.current.getFilters(),
      ...reqContextRef.current,
    }
    if (areSearchTripFiltersApplied) {
      payload = { ...payload, ...getUrlSearchParams() }
    }
    getOffers(payload)
      .then((res) => {
        requestsFailedCounter.current = 0
        reqContextRef.current = { context: res.data.context }
        const trips = skipTrips ? [] : res.data.trips
        const deals = skipDeals ? [] : res.data.deals
        if (trips.length === 0 && deals.length === 0) {
          loadMoreRef.current = false
          return
        }

        const pages = [...extraPages]
        pagesCount.current += 1
        pages.push(
          <Page
            key={pagesCount.current}
            trips={trips}
            deals={deals}
            structure={pageStructure}
          />,
        )
        loadMoreRef.current = res.data.have_more
        minPriceRef.current = getMinPrice(res.data.trips, minPriceRef.current)
        setExtraPages(pages)
      })
      .catch((err) => {
        // Change requestsFailedCounter, which will cause loadAnotherPage
        // in useInfiniteScroll to fire. Stop after 4th error
        if (requestsFailedCounter.current < 4) requestsFailedCounter.current++
      })
      .finally(() => {
        setIsLoadingAnotherPage(false)
      })
  }, [extraPages, pageStructure, areSearchTripFiltersApplied])

  useInfiniteScroll(loadAnotherPage, [
    pagesCount.current,
    requestsFailedCounter.current,
  ])

  // triggers loadAnotherPage if there is no scrollbar 
  useNoScrollbarCallback(loadAnotherPage, !initialTripsLoaded.current)

  const resetExtraPages = useCallback(() => {
    pagesCount.current = 1
    setExtraPages([])
  }, [setExtraPages])

  const updateFiltersAndFetch = useCallback(
    (updatedFilters, addSearchQueryToFetchRequest = true, alertTitles = {}) => {
      for (let key of Object.keys(updatedFilters)) {
        if (updatedFilters[key]) tmRef.current[key] = updatedFilters[key]
      }

      tmRef.current.updateRestoreFilters()

      tmRef.current.page = 0
      reqContextRef.current = { context: {} }
      let payload = { ...tmRef.current.getFilters(), ...reqContextRef.current }
      if (addSearchQueryToFetchRequest) {
        payload = { ...payload, ...getUrlSearchParams() }
      }

      fetchTrips(payload, alertTitles)
      if (!overrideStaticDeals) {
        setOverrideStaticDeals(true)
        hideStaticDeals()
      }
      resetExtraPages()
      skipFetchingTripsRef.current = false
      skipFetchingDealsRef.current = false
    },
    [fetchTrips, resetExtraPages, overrideStaticDeals, hideStaticDeals],
  )

  const applyAlertPriceFilters = useCallback(
    (alertTitles) => {
      closeAlerts()
      updateFiltersAndFetch({}, false, alertTitles)
      setAreSearchTripFiltersApplied(false)
      setShowAlerts(false)
    },
    [updateFiltersAndFetch, closeAlerts],
  )

  const closeSearchField = useCallback(() => {
    if (shareIcon) shareIcon.style.display = 'block'
    headerInput.style.visibility = 'hidden'
    lockScroll(false)
    setShowSearchField(false)
  }, [shareIcon, headerInput, lockScroll])

  const AlertsDropdown = () => (
    <AlertsContext.Provider
      value={{
        TM: tmRef.current,
        newAlertPostRef: newAlertPostRef,
        minPrice: minPriceRef.current,
        applyAlertPriceFilters: applyAlertPriceFilters,
        afterUserLogInCallbackRef: afterUserLogInCallbackRef,
      }}
    >
      <Alerts iconId="alerts_icon" onClose={closeAlerts} />
    </AlertsContext.Provider>
  )

  const searchTripProps = {
    TM: tmRef.current,
    updateFiltersAndFetch: updateFiltersAndFetch,
    areSearchTripFiltersApplied: areSearchTripFiltersApplied,
    setAreSearchTripFiltersApplied: setAreSearchTripFiltersApplied,
    updateTitlesEnabled: updateTitlesEnabled,
  }

  return process.env.NODE_ENV === 'production' || PRODUCTION_MODE ? (
    <>
      {sendUserPrefs && (
        <SendUserPrefs prefs={tmRef.current.getUserPreferences()} />
      )}
      <HeaderInputFieldPortal
        component={
          showSearchField && (
            <Search iconId="search_icon" onClose={closeSearchField} />
          )
        }
      />
      <CookieInformation />
      {showApps && <Apps iconId="apps_icon" onClose={closeApps} />}
      {showAlerts && <AlertsDropdown />}
      {showShare && <ShareButtons iconId="share_icon" onClose={closeShare} />}
      {showSearchTrip && <SearchTripPortal {...searchTripProps} />}
      {/* <SortingOptionsPortal
        updateFiltersAndFetch={updateFiltersAndFetch}
        onClose={hideSortingOptions}
      /> */}
      {tripsPortals.map((p) => (
        <TablePortal
          key={p.id}
          id={p.id}
          tripsArr={tripsArr.slice(
            p.firstTripIndex,
            p.firstTripIndex + p.tripsCount,
          )}
          isLoading={isLoadingTrips}
          tableSize={p.tripsCount}
        />
      ))}
      {overrideStaticDeals &&
        dealsPortals.map((p) => (
          <DealPortal key={p.id} dealData={dealsArr[p.index]} id={p.id} />
        ))}

      <ExtraPagesPortal
        pages={extraPages}
        isLoading={isLoadingAnotherPage}
        loadMore={loadMoreRef.current}
        loadAnotherPage={loadAnotherPage}
      />
      {errorCode && (
        <ErrorDialogPortal error={errorCode} onClose={resetError} />
      )}
    </>
  ) : (
    <>
      {sendUserPrefs && (
        <SendUserPrefs prefs={tmRef.current.getUserPreferences()} />
      )}
      <HeaderInputFieldPortal
        component={
          showSearchField && (
            <Search iconId="search_icon" onClose={closeSearchField} />
          )
        }
      />
      {showApps && <Apps iconId="apps_icon" onClose={closeApps} />}
      {showAlerts && <AlertsDropdown />}
      {showShare && <ShareButtons iconId="share_icon" onClose={closeShare} />}
      <Layout>
        {SHOW_PROMO && <Promo />}
        {SHOW_DEAL && (
          <>
            <Deal data={dealData} />
            <DontMiss />
          </>
        )}
        {showSearchTrip && (
          <SearchTrip
            productionMode={PRODUCTION_MODE}
            visible={!HIDE_FILTERS}
            {...searchTripProps}
          />
        )}
        <Box>
          <TableBorder>
            <TableHeader
              updateFiltersAndFetch={updateFiltersAndFetch}
              TableHeader={hideSortingOptions}
              showEditIcon={HIDE_FILTERS}
            />
            <Deals data={dealsArr[0]} />
            <MobileAppsWidget />
            {tripsPortals.map((p) => (
              <Fragment key={p.id}>
                <TripsTable
                  tripsArr={tripsArr.slice(
                    p.firstTripIndex,
                    p.firstTripIndex + p.tripsCount,
                  )}
                  isLoading={isLoadingTrips}
                />
                <Deals data={dealsArr[1]} />
                <Deals data={dealsArr[2]} />
              </Fragment>
            ))}
            <SocialMediaWidget />
            <ExtraPages
              pages={extraPages}
              isLoading={isLoadingAnotherPage}
              loadMore={loadMoreRef.current}
              loadAnotherPage={loadAnotherPage}
            />
          </TableBorder>
        </Box>
        {errorCode && <ErrorDialog error={errorCode} onClose={resetError} />}
      </Layout>
      <EndOfPage />
    </>
  )
}

const EndOfPage = () => (
  <div style={{ background: '#777', padding: '100px 10px' }}>
    <p style={{ color: 'white', fontSize: '1.5rem', lineHeight: '150%' }}>
      END OF PAGE.
      <br />
      CONTENT BELOW WILL BE HIDDEN ON PRODUCTION.
    </p>
  </div>
)

export default App
