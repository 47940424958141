import './destinationOptions.css'
import CheckboxField from 'components/UI/CheckboxField/CheckboxField'

const DestinationOptionsFiltered = ({ countries, regions, onClickOption }) => {
  const DestinationItem = ({ destData }) => (
    <li className="destination_item">
      <CheckboxField
        name={destData.textContent}
        checked={destData.selected}
        onClick={onClickOption}
      >
        <label>{destData.textContent}</label>
      </CheckboxField>
    </li>
  )


  if (countries.length + regions.length === 0) {
    return (
      <div className="destination_options">
        <p className="checkbox__field label">Brak wyników</p>
      </div>
    )
  }

  return (
    <div className="destination_options">
      <ul className="destinations_group">
        {countries.map((i) => (
          <DestinationItem key={i.textContent} destData={i} />
        ))}
      </ul>
      {countries.length > 0 && regions.length > 0 && <div className="destination_hr" />}
      <ul className="destinations_group">
        {regions.map((i) => (
          <DestinationItem key={i.textContent} destData={i} />
        ))}
      </ul>
    </div>
  )
}

export default DestinationOptionsFiltered
