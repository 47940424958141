import { decode } from 'html-entities'

import { URLS } from 'shared/constants'
import { formatPrice } from 'shared/utils'

import './deals.css'
import Badge from 'components/UI/Badge/Badge'

const Deals = ({ data }) => {
  if (!data) return

  return (
    <div className="deals__container">
      <a href={data.link} className="deals__header">
        <h2 className="deals__title">{decode(data.title)}</h2>
        <span className="deals__price">{formatPrice(data.cena)}</span>
      </a>
      <div className="deals__tags">
        <ul>
          {data.tags.map((t) => (
            <li key={t.name}>
              <a href={`${URLS.TRIPS}${t.slug}/`}>
                <Badge icon={t.icon}>{t.name}</Badge>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <a className="deals__content" href={data.link}>
        <div className="deals__text" dangerouslySetInnerHTML={{ __html: data.excerpt }} />
        <DealsFooter
          dateAgo={data.date_ago}
          updatedAgo={data.updated_ago}
          dateGmt={data.date}
          expired={data.expired}
        />
      </a>
    </div >
  )
}
export default Deals

export const DealsFooter = ({ dateGmt, dateAgo, updatedAgo, expired, largeMargin }) => {
  let className = 'deals__footer'
  if (largeMargin) className += ' deals__footer_lm'

  return (
    <p className={className}>
      <span className="deals__time" title={dateGmt}>
        {dateAgo}
      </span>
      {(expired || updatedAgo) && (
        <span className="deals__valid_until">
          {expired ? "Nieaktualne" : updatedAgo}
        </span>
      )}
    </p>
  )
}
