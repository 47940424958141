import { useMemo, useEffect, useCallback, useState, useRef } from 'react'

import { getUrlSearchParams, getAlertPrice } from 'App.helpers'
import { getAlertDescription } from 'components/Alerts/AlertsPanel/AlertsPrice/AlertPrice.helpers'
import { LOCAL_STORAGE_KEYS as KEYS } from 'shared/constants'
import { getLocalStorageData } from 'shared/utils'

const usePageStructure = () => {
  const pageStructure = useMemo(() => {
    const section = document.getElementById('extra_content')
    const structure = []
    let lastTripIndex = 0
    let dealsIndex = 0

    for (const node of section.childNodes) {
      if (node.nodeName !== 'DIV') continue

      if (node.id.startsWith('portal-table-')) {
        const count = +node.attributes['data-count'].value
        structure.push({
          type: 'trips',
          id: node.id,
          firstTripIndex: lastTripIndex,
          tripsCount: count,
        })
        lastTripIndex += count
      } else if (node.id.startsWith('portal-deals-')) {
        structure.push({
          type: 'deals',
          id: node.id,
          index: dealsIndex,
        })
        dealsIndex++
      }
    }
    return structure
  }, [])

  const tripPortals = useMemo(
    () => pageStructure.filter((item) => item.type === 'trips'),
    [pageStructure],
  )

  const dealsPortals = useMemo(
    () => pageStructure.filter((item) => item.type === 'deals'),
    [pageStructure],
  )

  const hideStaticDeals = useCallback(() => {
    const staticDeals = document.querySelectorAll('.deals__container_static')
    staticDeals.forEach((div) => {
      div.style.display = 'none'
    })
  }, [])

  return [pageStructure, tripPortals, dealsPortals, hideStaticDeals]
}

const useInfiniteScroll = (callback, useEffectDependenciesArr) => {
  const SCROLL_THRESHOLD = 0.98

  const getScrollPosition = useCallback(() => {
    const windowHeight =
      document.body.clientHeight - window.innerHeight ||
      document.documentElement.clientHeight ||
      window.innerHeight
    return window.scrollY / windowHeight
  }, [])

  const scrollHandler = useCallback(
    (e) => {
      if (getScrollPosition() > SCROLL_THRESHOLD) {
        e.currentTarget.removeEventListener('scroll', scrollHandler)
        callback()
      }
    },
    [callback, getScrollPosition],
  )

  useEffect(() => {
    if (getScrollPosition() > SCROLL_THRESHOLD) {
      // occurs when page is initially scrolled down
      callback()
    } else {
      window.addEventListener('scroll', scrollHandler)
    }

    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
    // eslint-disable-next-line
  }, [getScrollPosition, callback, scrollHandler, ...useEffectDependenciesArr])
}

const useClickStaticElement = (
  element_id,
  initial_state = false,
  onToggleCallback = null,
  onUnmountCallback = null,
) => {
  const [state, setState] = useState(initial_state)

  const element = useMemo(() => document.getElementById(element_id), [
    element_id,
  ])

  const toggleState = useCallback(() => {
    onToggleCallback && onToggleCallback(element, state)
    setState(!state)
  }, [state, onToggleCallback, element])

  const setStateFalse = useCallback(
    (event) => {
      setState(false)
      event && event.stopPropagation()
    },
    [setState],
  )

  useEffect(() => {
    element.addEventListener('click', toggleState)

    return () => {
      element.removeEventListener('click', toggleState)
      onUnmountCallback && onUnmountCallback()
    }
  }, [toggleState, element, onUnmountCallback])

  return { state, setStateFalse }
}

const useGetErrorFromURL = () => {
  const [error, setError] = useState(null)
  const resetError = useCallback(() => setError(null), [])

  useEffect(() => {
    let params = new URLSearchParams(window.location.search)
    let err = params.get('error')
    if (err != null) setError(err)
  }, [])

  return [error, resetError]
}

const useHeaderIcons = (
  setShowApps,
  setShowSearchField,
  setShowAlerts,
  setShowShare,
  newAlertPostRef,
  shareIcon,
  headerInput,
) => {
  const appsIcon = useMemo(() => document.getElementById('apps_icon'), [])
  const searchIcon = useMemo(() => document.getElementById('search_icon'), [])
  const alertsIcon = useMemo(() => document.getElementById('alerts_icon'), [])

  const lockScroll = useCallback((lock) => {
    if (lock) {
      const y = window.scrollY
      window.onscroll = () => window.scrollTo(0, y)
    } else {
      window.onscroll = null
    }
  }, [])

  const closeApps = useCallback(() => {
    lockScroll(false)
    setShowApps(false)
  }, [lockScroll, setShowApps])

  const closeAlerts = useCallback(() => {
    lockScroll(false)
    setShowAlerts(false)
  }, [lockScroll, setShowAlerts])

  const closeShare = useCallback(() => {
    lockScroll(false)
    setShowShare(false)
  }, [lockScroll, setShowShare])

  const toggleStateApps = useCallback(() => {
    setShowSearchField(false)
    setShowAlerts(false)
    setShowApps((prev) => {
      lockScroll(!prev)
      return !prev
    })
    setShowShare(false)
    if (shareIcon) shareIcon.style.display = 'block'
    headerInput.style.visibility = 'hidden'
  }, [
    setShowApps,
    setShowSearchField,
    setShowAlerts,
    setShowShare,
    shareIcon,
    headerInput,
    lockScroll,
  ])

  const toggleStateSearchField = useCallback(() => {
    setShowSearchField((prev) => {
      lockScroll(!prev)
      if (shareIcon) shareIcon.style.display = prev ? 'block' : 'none'
      headerInput.style.visibility = prev ? 'hidden' : 'visible'
      return !prev
    })
    setShowAlerts(false)
    setShowApps(false)
    setShowShare(false)
  }, [
    setShowApps,
    setShowSearchField,
    setShowAlerts,
    setShowShare,
    shareIcon,
    headerInput,
    lockScroll,
  ])

  const toggleStateAlerts = useCallback(() => {
    setShowSearchField(false)
    setShowAlerts((prev) => {
      lockScroll(!prev)
      return !prev
    })
    setShowApps(false)
    setShowShare(false)
    newAlertPostRef.current = null
    if (shareIcon) shareIcon.style.display = 'block'
    headerInput.style.visibility = 'hidden'
  }, [
    setShowApps,
    setShowSearchField,
    setShowAlerts,
    setShowShare,
    newAlertPostRef,
    shareIcon,
    headerInput,
    lockScroll,
  ])

  const toggleStateShare = useCallback(() => {
    setShowSearchField(false)
    setShowAlerts(false)
    setShowApps(false)
    setShowShare((prev) => {
      lockScroll(!prev)
      return !prev
    })
  }, [setShowApps, setShowSearchField, setShowAlerts, setShowShare, lockScroll])

  useEffect(() => {
    appsIcon.addEventListener('click', toggleStateApps)
    searchIcon.addEventListener('click', toggleStateSearchField)
    alertsIcon.addEventListener('click', toggleStateAlerts)
    shareIcon?.addEventListener('click', toggleStateShare)

    return () => {
      appsIcon.removeEventListener('click', toggleStateApps)
      searchIcon.removeEventListener('click', toggleStateSearchField)
      alertsIcon.removeEventListener('click', toggleStateAlerts)
      shareIcon?.removeEventListener('click', toggleStateShare)
    }
  }, [
    appsIcon,
    searchIcon,
    alertsIcon,
    shareIcon,
    toggleStateApps,
    toggleStateSearchField,
    toggleStateAlerts,
    toggleStateShare,
  ])

  return {
    closeApps: closeApps,
    closeAlerts: closeAlerts,
    closeShare: closeShare,
    lockScroll: lockScroll,
  }
}

const useAlertPostButton = (setShowAlerts, newAlertPostRef) => {
  useEffect(() => {
    const alertPostButton = document.getElementById('alert-post-button'),
      noBadgesSelectedDiv = document.querySelector('.dont_miss__info'),
      departureBadges = Array.from(
        document.querySelectorAll('.dont_miss__buttons span[data-sub-dep]'),
      ),
      destinationBadges = Array.from(
        document.querySelectorAll('.dont_miss__buttons span[data-sub-dest]'),
      ),
      seasonBadges = Array.from(
        document.querySelectorAll('.dont_miss__buttons span[data-sub-season]'),
      )

    const badges = [...departureBadges, ...destinationBadges, ...seasonBadges]
    badges.forEach((b) => {
      b.addEventListener('click', () => {
        b.classList.toggle('selected')
        noBadgesSelectedDiv.classList.remove('npi_visible')
      })
    })

    if (alertPostButton) {
      alertPostButton.addEventListener('click', () => {
        const departures = departureBadges
          .filter((b) => b.classList.contains('selected'))
          .map((b) => b.attributes[0].value)
        const destinations = destinationBadges
          .filter((b) => b.classList.contains('selected'))
          .map((b) => b.attributes[0].value)
        const seasons = seasonBadges
          .filter((b) => b.classList.contains('selected'))
          .map((b) => b.attributes[0].value)

        if ([...departures, ...destinations, ...seasons].length === 0) {
          noBadgesSelectedDiv.classList.add('npi_visible')
          return
        }

        newAlertPostRef.current = {
          departures: departures,
          destinations: destinations,
          seasons: seasons,
        }
        setShowAlerts(true)
      })
    }
  }, [setShowAlerts, newAlertPostRef])
}

const useTableHeaderIcons = () => {
  const sortIconEnabledRef = useRef(false),
    sortIcon = useMemo(() => document.getElementById('sort_table_icon'), []),
    sortingOptionsDiv = useMemo(
      () => document.getElementById('portal-sorting-options'),
      [],
    ),
    editFiltersIcon = useMemo(
      () => document.getElementById('edit_filters_icon'),
      [],
    )

  const hideSortingOptions = useCallback(
    (event) => {
      sortingOptionsDiv.classList.remove('thsor__open')
      event && event.stopPropagation()
    },
    [sortingOptionsDiv],
  )

  const toggleSortingOptions = useCallback(() => {
    if (!sortIconEnabledRef.current) return

    sortingOptionsDiv.classList.toggle('thsor__open')
  }, [sortingOptionsDiv])

  const updateSortIconState = useCallback(
    (enabled) => {
      sortIconEnabledRef.current = enabled
      const path = sortIcon.firstElementChild.firstElementChild
      if (enabled) {
        sortIcon.classList.add('icon_hover_effect')
        sortIcon.style.cursor = 'pointer'
        path.classList.remove('thbs_disabled')
      } else {
        sortIcon.classList.remove('icon_hover_effect')
        sortIcon.style.cursor = 'auto'
        path.classList.add('thbs_disabled')
      }
    },
    [sortIcon],
  )

  const showSearchTrip = useCallback(() => {
    document.getElementById('search_trip').style.display = 'block'
    document.getElementById('edit_filters_button').style.display = 'none'
  }, [])

  useEffect(() => {
    sortIcon.addEventListener('click', toggleSortingOptions)
    editFiltersIcon?.addEventListener('click', showSearchTrip)

    return () => {
      sortIcon.removeEventListener('click', toggleSortingOptions)
      editFiltersIcon?.removeEventListener('click', showSearchTrip)
    }
  }, [toggleSortingOptions, sortIcon, showSearchTrip, editFiltersIcon])

  return {
    hideSortingOptions: hideSortingOptions,
    updateSortIconState: updateSortIconState,
  }
}

const useTripsTableTitles = () => {
  const h1 = useMemo(() => document.getElementById('seo_h1'), [])
  const h2 = useMemo(() => document.getElementById('seo_h2'), [])

  const updateTripsTableTitles = useCallback(
    (title, subtitle) => {
      if (h1 && title) h1.textContent = title
      if (h2 && subtitle) h2.textContent = subtitle
    },
    [h1, h2],
  )

  return updateTripsTableTitles
}

const useAlertPriceController = (
  tmRef,
  reqContextRef,
  afterUserLogInCallbackRef,
  fetchTrips,
  referenceData,
  countryByRegion,
) => {
  const editFiltersIcon = useMemo(
    () => document.getElementById('edit_filters_icon'),
    [],
  )

  const applyAlertPrice = useCallback(
    (alert) => {
      if (editFiltersIcon) editFiltersIcon.style.visibility = 'visible'

      tmRef.current.activateExternalFilters(
        alert.filters_and,
        alert.filters_not,
      )
      tmRef.current.updateRestoreFilters()
      fetchTrips(
        {
          ...tmRef.current.getFilters(),
          ...getUrlSearchParams(),
          ...reqContextRef.current,
        },
        {
          h1: 'Twój alert',
          h2: getAlertDescription(alert, referenceData, countryByRegion),
        },
      )
    },
    [
      tmRef,
      reqContextRef,
      countryByRegion,
      fetchTrips,
      referenceData,
      editFiltersIcon,
    ],
  )

  const onApplyAlertPriceError = useCallback(
    (payload, loggedIn) => {
      if (editFiltersIcon) editFiltersIcon.style.visibility = 'hidden'

      fetchTrips(payload, {
        h1: 'Twój alert',
        h2: loggedIn
          ? 'Alert został usunięty i nie można zobaczyć wycieczek'
          : 'Musisz się zalogować do Alertów aby zobaczyć wycieczki',
      })
    },
    [fetchTrips, editFiltersIcon],
  )

  const processAlertPriceController = useCallback(
    (id, payload) => {
      getAlertPrice(id)
        .then((alert) => applyAlertPrice(alert))
        .catch(() => {
          const userData = getLocalStorageData(KEYS.USER_DATA)
          const loggedIn = !!userData && !!userData.token

          if (!loggedIn) {
            afterUserLogInCallbackRef.current = () =>
              processAlertPriceController(id, payload)
          }

          onApplyAlertPriceError(payload, loggedIn)
        })
    },
    [applyAlertPrice, onApplyAlertPriceError, afterUserLogInCallbackRef],
  )

  return {
    processAlertPriceController: processAlertPriceController,
  }
}

const useNoScrollbarCallback = (callback, skip = false) => {
  useEffect(() => {
    if (skip) return

    const scrollBarVisible = document.documentElement.scrollHeight > window.innerHeight;

    if (!scrollBarVisible) callback()
  }, [callback, skip])
}


export {
  usePageStructure,
  useInfiniteScroll,
  useClickStaticElement,
  useGetErrorFromURL,
  useHeaderIcons,
  useAlertPostButton,
  useTableHeaderIcons,
  useTripsTableTitles,
  useAlertPriceController,
  useNoScrollbarCallback,
}
