import { useState } from 'react'

import './destinationInputField.css'
import CloseIcon from 'components/UI/icons/CloseIcon'


const DestinationInputField = ({
  value,
  placeholder,
  onChange,
  onClickX,
}) => {
  const [isFocused, setIsFocused] = useState(false);


  let className = 'dest_input'
  if (isFocused) className += ' dest_input__focus'

  let inputClassName = 'dest_input__input'
  if (value.length === 0) inputClassName += ' dest_input__input_empty'

  return (
    <div className={className}>
      <input
        className={inputClassName}
        onChange={onChange}
        type="text"
        placeholder={placeholder}
        value={value}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {(value.length > 0) && <button type="button" onClick={onClickX}>
        <CloseIcon
          className="dest_input__close_icon icon_hover_effect"
          svgPathClass="dest_input__close_icon_svg"
          onClick={onClickX}
        />
      </button>
      }
    </div>
  )
}

export default DestinationInputField
