import styled from 'styled-components'

export const Container = styled.div`
  padding: 1rem 0.75rem 0.75rem;
  font-size: 1.2rem;
  text-align: justify;
`

export const Container2 = styled.div`
  padding: 2rem 0.75rem 0.25rem;
  font-size: 1.2rem;
  text-align: justify;
  @media screen and (max-width: 560px) {
    font-size: 1.25rem;
  }
`

export const P = styled.p`
  margin: 0 0 1rem;
  font: inherit;
  font-weight: ${(props) => (props.bold ? '600' : '400')};
  color: ${(props) =>
    props.error ? 'var(--color-red-1)' : 'var(--color-black)'};
  line-height: 125%;
  text-align: ${(props) => (props.center ? 'center' : 'justify')};
`

export const Link = styled.a`
  font: inherit;
  color: var(--color-blue-1);
  text-decoration: none;
  font-weight: 600;
`

export const BigButton = styled.button`
  text-align: center;
  display: block;
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0.75rem 1rem;
  margin: 0 auto;
  margin-top: ${(props) => (props.mt ? props.mt : 0)};
  margin-bottom: ${(props) => (props.mb ? props.mb : 0)};
  min-width: 120px;
  color: var(--color-white);
  background-color: ${(props) =>
    props.disabled ? 'var(--color-gray-4)' : 'var(--color-green-2)'};
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: var(--radius-sm);
  border: none;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  &:hover {
    background-color: ${(props) =>
      props.disabled ? 'var(--color-gray-4)' : 'var(--color-green-5)'};
  }
  @media screen and (max-width: 560px) {
    font-size: 1.25rem;
    padding: 1.25rem 1.5rem;
  }
  @media screen and (max-width: 500px) {
    min-width: 0;
  }
`

export const GreenBox = styled.div`
  display: block;
  padding: 1rem 0.75rem;
  font-size: 1.2rem;
  background-color: var(--color-green-7);
`

export const WhiteBox = styled.div`
  display: block;
  padding: ${(props) => (props.mb ? `1rem 0  ${props.mb}` : '1rem 0')};
  font-size: 1.2rem;
`
