import { useEffect, useCallback, useMemo } from 'react'

// Some components should be minimized on mobile until user clicks on it.
// This hook adds appriopriate click events as well as callbacks if needed by components.
const useMinimizedComponents = () => {
    const pricesInfo = useMemo(() => document.querySelector(".thpic_minimized"), []);
    const maximizePricesInfo = useCallback(() => {
        if (!pricesInfo) return

        if (pricesInfo.classList.contains("thpic_minimized")) {
            pricesInfo.classList.remove("thpic_minimized")
            pricesInfo.querySelector(".show_more_button").classList.remove("smb_visible");
        } else {
            pricesInfo.classList.add("thpic_minimized")
            pricesInfo.querySelector(".show_more_button").classList.add("smb_visible");
        }
    }, [pricesInfo]);

    useEffect(() => {
        if (!pricesInfo) return

        pricesInfo.addEventListener('click', maximizePricesInfo, true)

        return () => {
            document.removeEventListener('click', maximizePricesInfo, true)
        }
    }, [pricesInfo, maximizePricesInfo])


    const mission = useMemo(() => document.querySelector(".dpic_minimized"), []);
    const maximizeMission = useCallback(() => {
        if (!mission) return

        if (mission.classList.contains("dpic_minimized")) {
            mission.classList.remove("dpic_minimized")
            mission.querySelector(".show_more_button").classList.remove("smb_visible");
        } else {
            mission.classList.add("dpic_minimized")
            mission.querySelector(".show_more_button").classList.add("smb_visible");
        }
    }, [mission]);

    useEffect(() => {
        if (!mission) return

        mission.addEventListener('click', maximizeMission, true)

        return () => {
            document.removeEventListener('click', maximizeMission, true)
        }
    }, [mission, maximizeMission])
}

export { useMinimizedComponents }
