export const TYPES = {
  SOURCE: 'SOURCE',
  DESTINATION: 'DESTINATION',
  DATE: 'DATE',
  OTHER: 'OTHER',
  ALERT_FROM: 'ALERT_FROM',
  ALERT_TO: 'ALERT_TO',
  ALERT_SEASON: 'ALERT_SEASON',
}

export const CAPTIONS = {
  [TYPES.SOURCE]: {
    label: 'Gdzie zaczynasz podróż?',
    placeholder: 'Wybierz miasto wylotu',
  },
  [TYPES.DATE]: {
    label: 'Kiedy lecisz?',
    placeholder: 'Wybierz termin',
  },
  [TYPES.DESTINATION]: {
    label: 'Dokąd lecisz?',
    placeholder: 'Wybierz region',
  },
  [TYPES.OTHER]: {
    label: 'Dodatkowe opcje',
    placeholder: 'Wyżywienie, standard, cena',
  },
  [TYPES.ALERT_FROM]: {
    label: 'Gdzie zaczynasz podróż?',
    placeholder: 'Wybierz miasto wylotu',
  },
  [TYPES.ALERT_TO]: {
    label: 'Dokąd lecisz?',
    placeholder: 'Wybierz region',
  },
  [TYPES.ALERT_SEASON]: {
    label: 'Kiedy lecisz?',
    placeholder: 'Wybierz okres roku',
  },
}

export const OTHER_OPTIONS_LABEL = {
  price: 'Ile planujesz wydać?',
  rating: 'Jaki standard hotelu?',
  daysrange: 'Na jak długo lecisz?',
  boarding: 'Jakie wyżywienie?',
}
