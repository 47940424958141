import {
  formatDateYYYYMMDD,
  formatDateDDMMYYYY,
  pluralizeGwiazdka,
} from 'shared/utils'
import { TYPES } from './SearchTrip.constants'

export const getSourcesObj = (
  referenceData,
  fillWithTMValues = false,
  TM = null,
) => {
  let selectedSources = []

  if (fillWithTMValues && TM) {
    selectedSources = TM.checkAND?.source || []
  }

  return [
    {
      textContent: 'source',
      items: referenceData.source.map((item) => ({
        textContent: item,
        selected: selectedSources.includes(item),
      })),
    },
  ]
}

export const getDatesObj = (fillWithTMValues = false, TM = null) => {
  let selectedDates = {}

  if (fillWithTMValues && TM) {
    for (let key of ['startdate', 'enddate']) {
      let date = TM.checkAND[key]
      if (date) {
        selectedDates[key] = formatDateDDMMYYYY(new Date(date))
      }
    }
  }

  return [
    {
      textContent: 'startdate',
      items: [
        {
          textContent: 'od:',
          value: selectedDates?.startdate || '',
          isTextField: true,
        },
      ],
    },
    {
      textContent: 'enddate',
      items: [
        {
          textContent: 'do:',
          value: selectedDates?.enddate || '',
          isTextField: true,
        },
      ],
    },
  ]
}

export const getDestinationsObj = (
  referenceData,
  fillWithTMValues = false,
  TM = null,
) => {
  const result = []
  let selectedDestinations = []
  let selectedRegions = {}

  if (fillWithTMValues && TM) {
    selectedDestinations = TM.checkAND?.country || []
    selectedRegions = TM.getSelectedRegions()
  }

  for (let groupName of referenceData.destgroups) {
    const countries = []
    for (let country of referenceData[groupName]) {
      const regions = []
      for (let reg of referenceData[country]) {
        regions.push({
          textContent: reg,
          selected:
            selectedRegions.hasOwnProperty(country) &&
            selectedRegions[country].includes(reg),
        })
      }
      countries.push({
        textContent: country,
        items: regions,
        selected: selectedDestinations.includes(country),
      })
    }
    result.push({
      textContent: groupName,
      items: countries,
    })
  }

  return result
}

export const getOthersObj = (
  referenceData,
  fillWithTMValues = false,
  TM = null,
) => {
  const result = []

  let selectedOthers = {}

  if (fillWithTMValues && TM) {
    for (let key of ['price', 'rating', 'daysrange', 'boarding']) {
      let value = TM.checkAND[key]
      if (value) {
        selectedOthers[key] = value
      }
    }
  }

  for (let key of ['boarding', 'rating', 'daysrange']) {
    const items = []
    for (let item of referenceData[key]) {
      const data = {
        textContent: item,
        selected:
          selectedOthers.hasOwnProperty(key) &&
          selectedOthers[key].includes(item),
      }
      if (key === 'rating') {
        data.textDisplay = `${item} ${pluralizeGwiazdka(item)}`
      }
      if (key === 'daysrange') {
        data.textDisplay = `${item} dni`
      }
      items.push(data)
    }
    result.push({
      textContent: key,
      items: items,
    })
  }

  const priceObj = {
    textContent: 'price',
    items: [
      {
        textContent: 'Od:',
        value: '',
        isTextField: true,
      },
      {
        textContent: 'Do:',
        value: '',
        isTextField: true,
      },
    ],
  }

  if (selectedOthers?.price) {
    priceObj.items[0].value = selectedOthers.price[0] || ''
    priceObj.items[1].value = selectedOthers.price[1] || ''
  }

  result.push(priceObj)

  return result
}

export const getSelectedSimpleOptions = (options) => {
  return options[0].items.filter((i) => i.selected)
}

const getSelectedDateOptions = (options) => {
  const res = []
  for (let i of options) {
    for (let j of i.items) {
      if (j.value !== '')
        res.push({
          ...j,
          textDisplay: `${j.textContent} ${j.value.substr(0, 5)}`,
        })
    }
  }

  return res
}

const getSelectedOtherOptions = (options) => {
  const res = []
  for (let i of options) {
    for (let j of i.items) {
      if (j.selected) {
        res.push({ ...j })
      } else if (j.isTextField && j.value !== '') {
        res.push({ ...j, textDisplay: `${j.textContent} ${j.value} zł` })
      }
    }
  }

  return res
}

const getSelectedDestinationOptions = (options) => {
  const res = []
  for (let group of options) {
    for (let country of group.items) {
      let anyRegionSelected = false
      for (let region of country.items) {
        if (region.selected) {
          anyRegionSelected = true
          res.push({ ...region })
        }
      }
      if (country.selected && !anyRegionSelected) res.push({ ...country })
    }
  }

  return res
}

export const GETTERS = {
  [TYPES.SOURCE]: getSelectedSimpleOptions,
  [TYPES.DATE]: getSelectedDateOptions,
  [TYPES.DESTINATION]: getSelectedDestinationOptions,
  [TYPES.OTHER]: getSelectedOtherOptions,
  [TYPES.ALERT_FROM]: getSelectedSimpleOptions,
  [TYPES.ALERT_SEASON]: getSelectedSimpleOptions,
  [TYPES.ALERT_TO]: getSelectedDestinationOptions,
}

export const findIdArrForDestination = (destArr, destination) => {
  // function searches for given destination in destArr
  // and returns array of indexes to the object, when given given destination was found
  // example results:
  // [2,4]     - destination (country) was found in object at position 4 of countries array,
  //             which belongs to object at position 2 in groups array
  // [2,4,6]   - destination (region) was found in object at position 6 of regions array,
  //             which belongs to object at position 4 of countries array,
  //             which belongs to object at position 2 in groups array
  // null        destination was not found

  let result = null

  for (let i = 0; i < destArr.length; i++) {
    for (let j = 0; j < destArr[i].items.length; j++) {
      if (destArr[i].items[j].textContent === destination) {
        result = [i, j]
        break
      }
      for (let k = 0; k < destArr[i].items[j].items.length; k++) {
        if (destArr[i].items[j].items[k].textContent === destination) {
          result = [i, j, k]
          break
        }
      }
    }
  }

  return result
}

export const findIdArr = (optionsArr, optionName) => {
  // function searches for given optionName in optionsArr
  // and returns array of indexes to the object, when given given optionName was found
  // example results:
  // [2,4]     - optionName was found in object at position 4 of array,
  //             which belongs to object at position 2 in first level array
  // null        optionName was not found

  let result = null

  for (let i = 0; i < optionsArr.length; i++) {
    for (let j = 0; j < optionsArr[i].items.length; j++) {
      if (optionsArr[i].items[j].textContent === optionName) {
        result = [i, j]
        break
      }
    }
  }

  return result
}

export const getDataForTM = (referenceData, data) => {
  const checkAndObj = {},
    checkNotArr = []
  let countries = []

  for (let i of data) {
    const key = i.textContent
    if (['source', 'rating', 'daysrange', 'boarding'].includes(key)) {
      const selectedItems = i.items.filter((item) => item.selected)
      if (selectedItems.length !== 0) {
        checkAndObj[key] = selectedItems.map((item) => item.textContent)
      }
    } else if (['startdate', 'enddate'].includes(key)) {
      if (i.items[0].value) {
        checkAndObj[key] = formatDateYYYYMMDD(i.items[0].value)
      }
    } else if (key === 'price') {
      const priceArr = i.items.map((item) => (+item.value ? +item.value : null))
      if (priceArr.filter((i) => i === null).length !== 2) {
        // exclude [null, null]
        checkAndObj[key] = priceArr
      }
    } else if (referenceData.destgroups.includes(key)) {
      const selectedCountries = i.items.filter((item) => item.selected)
      countries = countries.concat(
        selectedCountries.map((item) => item.textContent),
      )
      for (let c of selectedCountries) {
        const unselectedRegions = c.items
          .filter((item) => !item.selected)
          .map((item) => item.textContent)
        if (unselectedRegions.length !== c.items.length) {
          checkNotArr.push(...unselectedRegions)
        }
      }
    }
  }

  if (countries.length !== 0) checkAndObj['country'] = countries

  return [checkAndObj, checkNotArr]
}

// returns object: { countries: [], regions: [] }
export const getFilteredDestinations = (destinations, phrase) => {
  function hasMatch(item) {
    return item.textContent.toLowerCase().startsWith(phrase.toLowerCase())
  }

  let countries = [];
  let regions = [];

  for (let i = 0; i < destinations.length; i++) {
    const group = destinations[i];

    for (let j = 0; j < group.items.length; j++) {
      const country = group.items[j];

      if (hasMatch(country)) countries.push(country)

      for (let k = 0; k < country.items.length; k++) {
        const region = country.items[k];
        if (hasMatch(region)) regions.push(region)
      }
    }
  }

  return {
    countries: countries,
    regions: regions
  }
}

// TODO aktualnie nie używane
export const filterDestinations = (destinations, phrase) => {
  if (phrase === undefined || phrase.trim() === '') return destinations

  function hasMatch(item) {
    return item.textContent.toLowerCase().startsWith(phrase.toLowerCase())
  }

  let ret = [];

  for (let i = 0; i < destinations.length; i++) {
    const group = destinations[i];
    const filteredCountries = [];

    for (let j = 0; j < group.items.length; j++) {
      const country = group.items[j];
      const filterdRegions = [];

      if (hasMatch(country)) {
        filteredCountries.push(country)
      } else {
        for (let k = 0; k < country.items.length; k++) {
          const region = country.items[k];
          if (hasMatch(region)) filterdRegions.push(region)
        }

        if (filterdRegions.length > 0) {
          filteredCountries.push({
            ...country, items: filterdRegions
          })
        }
      }
    }

    ret.push({ ...group, items: filteredCountries })
  }

  return ret
}

// TODO aktualnie nie używane
export const getCountriesWithMatchingRegions = (destinations, phrase) => {
  if (phrase === undefined || phrase.trim() === '') return []

  function hasMatch(item) {
    return item.textContent.toLowerCase().startsWith(phrase.toLowerCase())
  }

  let ret = [];

  for (let i = 0; i < destinations.length; i++) {
    const group = destinations[i];
    // console.log(group)
    for (let j = 0; j < group.items.length; j++) {
      const country = group.items[j];
      // console.log(" -", country)

      const anyRegionMatch = country.items.some((region) => hasMatch(region))

      // console.log("match?", anyRegionMatch)
      if (anyRegionMatch) ret.push(country.textContent)
    }
  }

  return ret
}
