import './searchTripButtons.css'
import DropdownIcon from 'components/UI/icons/DropdownIcon'


const DropdownButton = ({ collapsed, onClick, forceVisibleOnMobile }) => {
  let classes = "search_trip__dropdown_icon";
  if (forceVisibleOnMobile) classes += " stdi__visible_on_mobile";

  return (
    <div className="search_trip__dropdown_icon_container" onClick={onClick}>
      <DropdownIcon
        className={classes}
        style={{
          transform: `rotate(${collapsed ? 0 : 180}deg)`,
        }}
      />
    </div>
  )
}

export default DropdownButton
